<template>
  <v-card :loading="!editedItem || pLoading">
    <v-toolbar :class="{ 'title-padding': pIsDialog }" flat>
      <v-toolbar-title>
        {{ pCardTitle }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text class="pb-0">
      <div class="item-fields-container">
        <div v-for="item in displayItemParams" :key="item.value">
          <CustomInputComponent
            :pEditedItem="editedItem"
            :pDisabledParams="pDisabledParams"
            :pComponentData="item"
            :pValue="editedItem[item.value]"
            :pLoading="pLoading"
            :pDisabled="disabled"
            @onValueChanged="fieldValueChanged()"
            ref="itemTextFields"
          />
        </div>
      </div>
    </v-card-text>

    <v-card-actions v-if="pShowActions" class="add-edit-component-actions">
      <v-spacer></v-spacer>
      <v-btn
        class="item-edit-btn"
        :loading="pLoading"
        text
        color="accent"
        v-if="!pIsDialog && disabled"
        @click="enableFields"
      >
        Edit
      </v-btn>
      <v-btn
        class="item-cancel-btn"
        v-if="(pIsDialog || !disabled) && pShowCancelBtn"
        text
        @click="pIsDialog ? $emit('onCancelled') : removeChanges()"
        :disabled="pLoading"
      >
        {{pIsDialog ? "Close" : "Cancel"}}
      </v-btn>
      <v-btn
        class="item-add-or-save-btn"
        color="primary"
        text
        v-if="(pIsDialog || !disabled) && pCanSave"
        :disabled="!dirty || !valid"
        @click="addOrSaveItem"
      >
        {{ editedItem && editedItem.id ? "Save" : "Add" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import lodash from "lodash";
import { Host } from "@/model/host.model";
import { Team } from "@/model/team.model";
import {
  getAllDataFromCustomInputComponents,
  HOST_ADD_EDIT_ITEM,
  TEAM_ADD_EDIT_ITEM,
  DOUBLE_FIELDS_TYPE,
} from "@/utils/utils";

export default {
  name: "AddEditComponent",

  components: {
    CustomInputComponent: () => import("./InputComponent.vue"),
  },

  props: {
    pCardTitle: String,

    pEditedItem: Object,

    pEditedItemType: String,

    pItemParams: Array,

    pIsDialog: {
      type: Boolean,
      default: false,
    },

    pLoading: {
      type: Boolean,
      default: false,
    },

    pDisabledParams: Array,

    pBeforeItemCreatedOrUpdated: {
      type: Function,
      default: undefined,
    },

    pCanSave: {
      type: Boolean,
      default: true,
    },

    pPermanentDisable: {
      type: Boolean,
      default: false,
    },

    pPermanentActive: {
      type: Boolean,
      default: false,
    },

    pShowCancelBtn: {
      type: Boolean,
      default: true,
    },

    pVisibilityRules: Array,

    pShowActions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      itemParams: [...this.pItemParams],
      HOST_ADD_EDIT_ITEM,
      disabled: !this.pIsDialog,
      dirty: false,
      valid: true,
    };
  },

  computed: {
    displayItemParams() {
      return this.itemParams.map((param) => ({
        ...param,
        editable: !this.pDisabledParams.includes(param.value),
      }));
    },

    editedItem() {
      if (!this.pEditedItem) {
        return undefined;
      }

      if (this.pEditedItemType == HOST_ADD_EDIT_ITEM) {
        return Host.fromDto(this.pEditedItem);
      }

      if (this.pEditedItemType == TEAM_ADD_EDIT_ITEM) {
        return Team.fromDto(this.pEditedItem);
      }

      return this.pEditedItem;
    },
  },

  methods: {
    removeChanges() {
      this.$refs.itemTextFields.map((item) => item.removeChanges());
      this.disableComponent();
    },

    enableFields() {
      this.disabled = false;
    },

    addOrSaveItem() {
      const item = getAllDataFromCustomInputComponents(
        this.getAllFields(),
        { id: this.editedItem.id },
      );

      let emittedItem = item;

      if (this.pEditedItemType == HOST_ADD_EDIT_ITEM) {
        emittedItem = item;
      }

      if (this.pEditedItemType == TEAM_ADD_EDIT_ITEM) {
        emittedItem = item;
      }

      if (!this.pBeforeItemCreatedOrUpdated || this.pBeforeItemCreatedOrUpdated(emittedItem)) {
        this.$emit("onItemCreatedOrUpdated", emittedItem);
        this.disableComponent();
      }
    },

    disableComponent() {
      if (!this.pIsDialog && !this.pPermanentActive) {
        this.disabled = true;
      }
      this.dirty = false;
      this.valid = false;
    },

    fieldValueChanged() {
      const childComponents = this.$refs.itemTextFields;

      this.itemParams = this.getItemParams(
        childComponents.map((item) => item.pComponentData),
        childComponents,
      );

      Vue.nextTick(() => {
        const allFields = this.getAllFields();

        this.dirty = !!allFields.find(
          (item) => item.isChanged
            && item.pComponentData.editable
            && item.pComponentData.visible,
        );

        this.valid = allFields
          && allFields.length
          && !allFields.find(
            (item) => item.validationResult !== true
              && item.pComponentData.editable
              && item.pComponentData.visible,
          );
      });
    },

    getAllFields() {
      let allFields = [];

      this.$refs.itemTextFields.forEach((item) => {
        if (item.pComponentData.componentType == DOUBLE_FIELDS_TYPE) {
          allFields = allFields.concat(item.$children);
        } else {
          allFields.push(item);
        }
      });

      return allFields;
    },

    getItemParamsWithUpdatedVisibility(values) {
      return this.itemParams.map((param) => ({
        ...param,
        visible: !values.includes(param.value),
      }));
    },

    getItemParams(params, childComponents) {
      if (this.pVisibilityRules) {
        // we check this.editedItem on start working
        // we check childComponents when component in work
        const itemsForChangeVisibility = lodash.flatten(this.pVisibilityRules
          .map((getInvisibleItems) => (
            getInvisibleItems(this.editedItem, childComponents)
          )))
          .filter((it) => it);

        if (itemsForChangeVisibility.length) {
          return this.getItemParamsWithUpdatedVisibility(itemsForChangeVisibility);
        }
      }

      return [...this.pItemParams];
    },
  },

  mounted() {
    if (this.pPermanentDisable && this.pPermanentActive) {
      throw Error("Conflict with pPermanentDisable and pPermanentActive");
    }

    if (this.pPermanentDisable) {
      this.disabled = true;
    }

    if (this.pPermanentActive) {
      this.enableFields();
    }

    this.itemParams = this.getItemParams(this.itemParams);
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-height: 900px) {
  .v-dialog .item-fields-container {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
